import Link from "next/link";
import { useSession } from "next-auth/react";
import { useSelector, useDispatch } from "react-redux";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { globalActionWatchFactory } from '../../utils/managedSub'
import { GlobalActionTypes } from '../../utils/enums';
import { scroller } from 'react-scroll';

const Hero = (props) => {
  const { data: session, status } = useSession();
  const { subStatus } = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const { showDesign } = props

  function handleDesign() {
    // if () {
    //   dispatch(loginModalShow(true));
    // } else
    if (!globalActionWatchFactory({
      action: GlobalActionTypes.HOMEPAGE_CREATE_DESIGN,
      session: session,
      subStatus: subStatus,
      dispatch: dispatch
    })) {
      return;
    }
    showDesign && showDesign(true)
  }

  const handleScroll = (targetId) => {
    scroller.scrollTo(targetId, {
      duration: 800,
      delay: 0,
      offset: -150,
      smooth: 'linear'
    });
  };

  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        {/* <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        /> */}
      </picture>
      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1 className="text-jacarta-700 font-bold font-display mb-6 capitalize text-center text-4xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
              Design your dream home with Davinte.AI
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              Your journey towards a stunning rendering begins here!
            </p>
            <div className="flex space-x-4">
              {session ? (
                // <Link
                //   href="/ideas"
                //   className="button-main flex justify-center h-full">
                //   Start Designing
                // </Link>
                <span
                  onClick={() => handleDesign()}
                  className="button-main flex justify-center h-full">
                  Start Designing
                </span>
              ) : (
                <span
                  onClick={() => handleDesign()}
                  className="button-main flex justify-center h-full">
                  Try it For Free
                </span>
              )}

              {session ? (
                <Link
                  href="/explore"
                  className="button-main self-center h-full bg-white border-jacarta-50 text-jacarta-500 hover:bg-accent hover:text-white dark:bg-white dark:border-jacarta-50 dark:text-jacarta-500 dark:hover:bg-accent dark:hover:text-white flex justify-center">
                  Explore
                </Link>
              ) : (
                <Link
                  href="/explore"
                  className="button-main self-center h-full bg-white border-jacarta-50 text-jacarta-500 hover:bg-accent hover:text-white dark:bg-white dark:border-jacarta-50 dark:text-jacarta-500 dark:hover:bg-accent dark:hover:text-white flex justify-center">
                  Explore
                </Link>
              )}
            </div>
          </div>
          {session && (<div
            className="fixed w-10 bottom-10 right-10 bg-red h-10 md:hidden rounded-full flex items-center justify-center cursor-pointer z-10"
            onClick={() => handleDesign(true)}>
            <i className="fal fa-plus text-xl text-white"></i>
          </div>)
          }
          {/* <!-- Hero image --> */}
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <div className="mt-8 inline-block md:rotate-[8deg] w-10/12 lg:w-[24rem] xl:w-[35rem] rounded-lg overflow-hidden">
                <ReactCompareSlider
                  position={80}
                  itemOne={
                    <ReactCompareSliderImage
                      src="https://davinte.nyc3.cdn.digitaloceanspaces.com/uploaded_photos/hero/davinteai_1.jpg"
                      alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src="https://davinte.nyc3.cdn.digitaloceanspaces.com/uploaded_photos/clexsv7hw0000f3585z8cs6va/dda40844da25ff4fb021db699e589de04028995977286994b2e0c0e71899e2c8_image.jpg"
                      alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
