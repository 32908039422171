import Link from "next/link";
import { useSession } from "next-auth/react";
import { useSelector, useDispatch } from "react-redux";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import { globalActionWatchFactory } from "../../utils/managedSub";
import { GlobalActionTypes } from "../../utils/enums";
import { scroller } from "react-scroll";
import Image from "next/image";
import { defaultBlurData } from "../../utils/common"


const HeroFull = (props) => {
  const { data: session, status } = useSession();
  const { subStatus } = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  const { showDesign } = props;

  function handleDesign() {
    // if () {
    //   dispatch(loginModalShow(true));
    // } else
    if (
      !globalActionWatchFactory({
        action: GlobalActionTypes.HOMEPAGE_CREATE_DESIGN,
        session: session,
        subStatus: subStatus,
        dispatch: dispatch,
      })
    ) {
      return;
    }
    showDesign && showDesign(true);
  }

  const handleScroll = (targetId) => {
    scroller.scrollTo(targetId, {
      duration: 800,
      delay: 0,
      offset: -150,
      smooth: "linear",
    });
  };

  return (
    <section className="relative h-screen">
      <div className="full-slider h-screen">
        <div className="swiper-slide after:hero-cover-full after:absolute after:inset-0">
          <div className="relative z-10 h-full pt-40">
            <div className="absolute lg:top-1st top-25p short:top-25p">
              <div className="container">
                <h2 className="font-hero-main title">
                  RENDERING AT THE SPEED OF IMAGINATION
                </h2>
                <h2 className="font-hero-main subtitle">
                  AI POWERED ENGINE
                </h2>
                <h2 className="font-hero-main subtitle2">
                  1000X FASTER THAN TRADITIONAL RENDERING SOLUTIONS
                </h2>
              </div>
            </div>
            <div className="absolute lg:bottom-13 md:bottom-12 bottom-9 short:bottom-9">
              <div className="container">
                <div className="flex space-x-4 xl:ml-20">
                  {session ? (
                    // <Link
                    //   href="/ideas"
                    //   className="button-main flex justify-center h-full">
                    //   Start Designing
                    // </Link>
                    <span
                      onClick={() => handleDesign()}
                      className="button-main-hero flex justify-center border-0 hover:bg-white hover:text-white hover:text-jacarta-900"
                    >
                      Design
                    </span>
                  ) : (
                    <span
                      onClick={() => handleDesign()}
                      className="button-main-hero flex justify-center border-0 hover:bg-white hover:text-white hover:text-jacarta-900"
                    >
                      Try For Free
                    </span>
                  )}

                  {session ? (
                    <Link
                      href="/explore"
                      className="button-main-hero self-center bg-white border-0 border-jacarta-50 text-jacarta-900 hover:bg-accent hover:text-white dark:bg-white dark:border-jacarta-50 dark:text-jacarta-900 dark:hover:bg-accent dark:hover:text-white flex justify-center"
                    >
                      Gallery
                    </Link>
                  ) : (
                    <Link
                      href="/explore"
                      className="button-main-hero self-center bg-white border-0 border-jacarta-50 text-jacarta-900 hover:bg-accent hover:text-white dark:bg-white dark:border-jacarta-50 dark:text-jacarta-900 dark:hover:bg-accent dark:hover:text-white flex justify-center"
                    >
                      Gallery
                    </Link>
                  )}
                </div>
              </div>
            </div>
            {/* <h2 className="font-display text-2xl font-semibold text-white">
                Davinte
            </h2> */}
          </div>
          <Image
            width={3584} height={1600} priority={true} loading="eager"
            src="/images/page-title/hero-davinte-ai-desktop.jpg"
            // placeholder='blur'
            // blurDataURL={defaultBlurData}
            alt="hero"
            className="absolute inset-0 h-full w-full object-cover xl:block"
          />
          <Image
            width={3584} height={1600} priority={true} loading="eager"
            src="/images/page-title/hero-davinte-ai-mobile.jpg"
            // placeholder='blur'
            // blurDataURL={defaultBlurData}
            alt="hero"
            className="absolute inset-0 h-full w-full object-cover xl:hidden"
          />
          {session && (
            <div
              className="fixed w-10 bottom-10 right-10 bg-red h-10 md:hidden rounded-full flex items-center justify-center cursor-pointer z-10"
              onClick={() => handleDesign(true)}
            >
              <i className="fal fa-plus text-xl text-white"></i>
            </div>
          )}
          {/* <Image
            src={image}
            className="absolute inset-0 h-full w-full object-cover"
            alt={title}
            layout="fill"
          /> */}
        </div>
      </div>
      {/* <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
      </picture> */}
      {/* <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1 className="text-jacarta-700 font-bold font-display mb-6 capitalize text-center text-4xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
              Design your dream home with Davinte.AI
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              Your journey towards a stunning interior begins here!
            </p>
            <div className="flex space-x-4">
              {session ? (
                // <Link
                //   href="/ideas"
                //   className="button-main flex justify-center h-full">
                //   Start Designing
                // </Link>
                <span
                  onClick={() => handleDesign()}
                  className="button-main flex justify-center h-full"
                >
                  Start Designing
                </span>
              ) : (
                <span
                  onClick={() => handleDesign()}
                  className="button-main flex justify-center h-full"
                >
                  Try it For Free
                </span>
              )}

              {session ? (
                <Link
                  href="/explore"
                  className="button-main self-center h-full bg-white border-jacarta-50 text-jacarta-500 hover:bg-accent hover:text-white dark:bg-white dark:border-jacarta-50 dark:text-jacarta-500 dark:hover:bg-accent dark:hover:text-white flex justify-center"
                >
                  Explore
                </Link>
              ) : (
                <Link
                  href="/explore"
                  className="button-main self-center h-full bg-white border-jacarta-50 text-jacarta-500 hover:bg-accent hover:text-white dark:bg-white dark:border-jacarta-50 dark:text-jacarta-500 dark:hover:bg-accent dark:hover:text-white flex justify-center"
                >
                  Explore
                </Link>
              )}
            </div>
          </div>
          
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <div className="mt-8 inline-block md:rotate-[8deg] w-10/12 lg:w-[24rem] xl:w-[35rem] rounded-lg overflow-hidden">
                <ReactCompareSlider
                  position={80}
                  itemOne={
                    <ReactCompareSliderImage
                      src="https://davinte.nyc3.cdn.digitaloceanspaces.com/uploaded_photos/hero/davinteai_1.jpg"
                      alt="Davinte.AI Interior and Architecture Design Platform"
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      src="https://davinte.nyc3.cdn.digitaloceanspaces.com/uploaded_photos/clexsv7hw0000f3585z8cs6va/dda40844da25ff4fb021db699e589de04028995977286994b2e0c0e71899e2c8_image.jpg"
                      alt="Davinte.AI Interior and Architecture Design Platform"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default HeroFull;
